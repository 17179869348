body.open-modal {
  overflow: hidden;
  position:fixed;
  width: 100%;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  bottom:0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  height: 100vh;

  .close-modal {
    color: #fff;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    margin:-30px auto 0;
    text-align: right;
    z-index:10;
    width:300px;
    padding-bottom:10px;

    span {
      cursor: pointer;
    }
  }
}

.modal-container {
  width: 300px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
  max-height: 80%;
  padding:15px;
  overflow-y: auto;

  .btn-primary {
    min-width: inherit;
  }
}

.modal-header h3 {
  margin: 0;
  color: #000;
}

.modal-body {
  margin: 0;

  .alert-danger {
    margin-bottom: 0;
  }
}

.modal-default-button {
  float: right;
}

.fluid-modal, .modal-mask {
  .modal-wrapper {
    .close-modal {
      max-width: 656px;
      width: 100%;
    }
    .modal-container {
      padding: 15px;
      max-width: 656px;
      width: 100%;
    }
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.no-footer {
  .modal-footer {
    display: none;
  }
}

.no-header {
  .modal-header {
    display: none;
  }
}

.no-close {
  .close-modal {
    display: none;
  }
}

.no-borders {
  .modal-footer,
  .modal-header {
    border: none;
  }
}

@media (max-width: 767px) {
  .modal-wrapper {
    padding:0 15px;
  }
}

.welcome-modal {
  .link {
    color: #FA6362;
  }
}

body.photo-club-body {
  @media screen and (max-width: 768px) {
    .modal-container {
      height: 100% !important;
      max-height: 85% !important;
    }

    .modal-mask {
      height: 100% !important;
    }

    .modal-wrapper {
      height: 100% !important;
    }
  }
}