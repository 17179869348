#auth-body {
background: url('/images/background-image-front2.jpg') no-repeat center center;
background-size: cover;
min-height: 601px;

    a:not(.btn) {
        &:hover {
            text-decoration: underline;
        }
    }

    .input-group-addon-custom {
        background-color: #fff;
    }

    .input-group-custom {
        .form-control {
            border-left: none;

        }
    }

    .error-block {
        background-color: rgba(255, 255, 255, 0.7);
        margin-top: 10px;
        margin-bottom: 10px;
        border: 2px solid #e43427;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
        padding: 5px;
        ul {
            margin: 0px;
            li {
                list-style: none;
                color: rgba(0, 0, 0, 0.6);

            }
        }
    }

    .status-block {
        background-color: rgba(255, 255, 255, 0.7);
        margin-top: 10px;
        margin-bottom: 10px;
        border: 2px solid #80e495;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
        padding: 5px;
        ul {
            margin: 0px;
            li {
                list-style: none;
                color: rgba(0, 0, 0, 0.6);

            }
        }
    }

    .warning-block {
        background-color: rgba(255, 255, 255, 0.7);
        margin-top: 10px;
        margin-bottom: 10px;
        border: 2px solid #e49f2e;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
        padding: 5px;
    }

    .form-group {
        h2 {
            color: rgba(255, 255, 255, 0.9);
            margin: 0px;
            font-size: 1.5em;;
        }
    }

    #locale-select {
        background-color: rgba(92, 73, 66, 0.5);
        border: solid 2px rgb(255, 255, 255);
        color: rgba(255, 255, 255, 0.8);
        font-weight: bold;
        font-size: 1.1em;
        outline: none;
        border-radius: 5px;
        padding: 5px;
        min-width: 100px;
        margin: 10px;
        cursor: pointer;

        &:hover {
            border: solid 2px rgb(233, 233, 233);
            background-color: rgb(233, 233, 233);
            color: rgba(0, 0, 0, 0.8);
        }

        option {
            color: rgba(0, 0, 0, 0.8);
            font-weight: bold;
            outline: none;
        }
    }

    #signup-form-block {
        background-color: rgba(92, 73, 66, 0.7);
        color: rgba(255, 255, 255, 0.9);
        .form-group {
            &.required {
                .control-label:after {
                    content: " *";
                    color: rgba(255, 255, 255, 0.9);
                    font-weight: bold;
                }
            }
        }
        .required-star {
            color: rgba(255, 255, 255, 0.9);
            font-weight: bold;
        }
    }

    #register-block,
    #recover-block {
        margin-top: 17%;
        background-color: rgba(92, 73, 66, 0.5);
        color: rgba(255, 255, 255, 0.9);
        .btn {
            border: solid 2px rgba(255, 255, 255, 1);
            background-color: rgba(255, 255, 255, 0.1);
            color: rgba(255, 255, 255, 0.9);
            font-size: 1.1em;
            width: 150px;
            &:hover {
                background-color: rgba(255, 255, 255, 0.9);
                color: rgba(0, 0, 0, 0.8);
            }
        }
    }
}