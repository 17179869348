.sidebar-nav {
  position: absolute;
  top: 0;
  width: 300px;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 2px;

  li {
    text-indent: 15px;
    line-height: 40px;

    a {
      display: block;
      text-decoration: none;
      color: #888;
      &:hover {
        text-decoration: none;
        color: #444;
        background: #eee;
        border-right: $brand-primary 2px solid;
      }
      &:active, &:focus {
        text-decoration: none;
      }
    }
    ul {
      li {
        list-style-type: none;
        a {
          list-style-type: none;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }

  .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
    a {
      color: #999999;
      &:hover {
        color: #fff;
        background: none;
      }
    }
  }
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  color: $brand-primary;
  background-color: #e9e9e9;
  border-right: $brand-primary 2px solid;
}
.nav-pills > li > a {
  border-radius: 0px;
}