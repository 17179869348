.gray-body {
  background-color: #f2f2f2;
}

.corporate-albums-overview {
  table {
    a {
      text-decoration: none;

      &:hover {
        color: #e43427;
      }
    }
  }
}

footer * {
  font-family: Lato, sans-serif;
  color: white;
  line-height: 17px;
}

.corporate-designer footer {
  position: fixed;
}

.d-inline-block {
  display: inline-block !important;
}

.lp-campaign-form {
  .tab-content {
    .tab-pane.active {
      display: block;
    }

    .tab-pane {
      display: none;
    }
  }

  .lp-section-link {
    background: #fdeccf;
  }

  .delete-section {
    margin-left: 4px;
    font-size: 16px;
    cursor: pointer;
  }

  .card {
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    padding: 10px;
    margin: 8px 3px;
    -webkit-box-shadow: 3px 5px 7px 1px #d4d4d4;
    box-shadow: 3px 5px 7px 1px #d4d4d4;
  }
}

.no-gutters {
  padding: 0;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}