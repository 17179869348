#image-upload-container {

    label {
        padding: 10px;
        cursor: pointer;
        display: block;
        border: dotted 2px $gray-light;
        background-color: $gray-lighter;
        width: 100%;
        text-align: center;

        &:hover {
            background-color: darken($gray-lighter, 5%) !important;
        }

        #bg_image {
            display: none;
        }

        #empty-upload-block {
            height:250px;
            margin-top:50px;
            i {
                font-size: 12em;
            }
            .text {
                font-size: 2em;
            }
        }

        #selected-upload-block {
            display: none;
            #selected-file-thumbnail {
                max-width:100%;
                max-height: 275px;
            }
        }
    }

    #cancel-select {
        display: none;
    }
}