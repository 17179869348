.cookie-consent {
  text-align: center;
  position: fixed;
  bottom: 0;
  background: white;
  width: 100%;
  margin: 0 auto;
  padding:  7px 0 12px;
  border-left: 1px solid;
  border-top: 1px solid;
  border-right: 1px solid;
  /* needed for mobile */
  z-index: 10000;

  * {
    font-size: 14px;
    font-weight: 400;
  }

  button {
    /* basically all these styles reset the button */
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    outline: none;
    text-align: inherit;
    -webkit-appearance: none;
  }
  a, button {
    text-decoration: underline;
  }
}