#preview-container {
  width: 375px;

  #tshirt-icon-block {
    display: none;
    margin-top: 20px;
    margin-left: 40px;
    width: 100%;

    a {
      color: inherit;
    }

    #tshirt-rotate-block {
      float:left;
      width: 80px;
      height: 20px;
      text-align: left;
      margin: 5px;
      margin-left:20px;
    }

    #tshirt-move-hint {
      float:right;
      width: 80px;
      height: 20px;
      text-align: right;
      margin: 5px;
      margin-right: 70px;
    }
  }

  #width {
    width: 100%;
    float: right;

    .canvas_width {
      float: right;
      text-align: center;

      #preview-format-width {
        margin-bottom: 5px;
      }

      .left-arrow {
        position: relative;
        bottom: -3px;
        display: block;
        width: 2px;
        height: 20px;
        float: left;
        background-color: $gray-light;

      }

      .right-arrow {
        position: relative;
        bottom: -3px;
        float: right;
        display: block;
        width: 2px;
        height: 20px;
        background-color: $gray-light;
      }
    }
  }

  #width-bottom {
    width: 100%;
    height: 20px;
    float: right;

    .canvas_width {
      float: right;
      text-align: center;
      height: 20px;

      .left-arrow {
        display: block;
        width: 2px;
        height: 20px;
        float: left;
        background-color: $gray-light;
      }

      .right-arrow {
        float: right;
        display: block;
        width: 2px;
        height: 20px;
        background-color: $gray-light;
      }

      #move-hint {
        float: right;
        width: 80px;
        height: 20px;
        text-align: right;
        margin: 5px;
      }

      #rotate-block {
        display: none;
        float: left;
        width: 80px;
        height: 20px;
        text-align: left;
        margin: 5px;

        a {
          color: inherit;
          &:hover {
            color: $brand-primary;
          }
        }
      }

    }

  }

  #height {
    float: right;
    width: 20px;
    margin-right: -20px;

    .canvas_height {
      position: absolute;

      #preview-format-height {
        height: 30px;
        white-space: nowrap;
        position: relative;
        top: 50%;
        margin-top: -30px;
        margin-left: 5px;

      }

      .top-arrow {
        position: relative;
        top: 0px;
        left: 2px;
        display: block;
        width: 20px;
        height: 2px;
        background-color: $gray-light;
      }

      .bottom-arrow {
        position: absolute;
        bottom: 0px;
        left: 2px;
        display: block;
        width: 20px;
        height: 2px;
        background-color: $gray-light;
      }

    }
  }

  #height-left {
    float: right;
    width: 20px;
    margin-right: 2px;

    .canvas_height {
      position: relative;

      .top-arrow {
        position: relative;
        top: 0px;
        left: -2px;
        display: block;
        width: 20px;
        height: 2px;
        background-color: $gray-light;
      }

      .bottom-arrow {
        position: absolute;
        bottom: 0px;
        left: -2px;
        display: block;
        width: 20px;
        height: 2px;
        background-color: $gray-light;
      }
    }
  }
  .canvas-container {
    float: right;
  }

  #myCanvas {
    position: relative;
    left: 0;
    top: 0;
    border: 0;

  }
}

.rotate-preview {
  margin-left:35px;
}

#zoom-container {
  margin-top: 20px;
  margin-left: 40px;
  width: 100%;

  .zoom-icons {
    float: left;
    width: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }

  #zoom-slider-container {
    width: 100%;
    text-align: center;
    float: left;
    margin-right: 5px;

    #zoom {
      .slider-selection {
        background: lighten($brand-primary, 10%)
      }
      .slider-handle {
        background: $brand-primary;
      }
      .slider-track-high {
        background: #e1e1e1;
      }
    }
  }
}

a.list-group-item {
  cursor: pointer;

  &.active {
    color: #fff;
    background-color: $brand-primary;
    border-color: $brand-primary;
  }
}

.btn-rotate {
  border-color: #ccc;
}
