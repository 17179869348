.navbar-default {
    margin-bottom: 0px;
    background-color: #313132;
    .navbar-nav {
        > li {
            > a {
                color: #c2c2c2;
                &:hover {
                    color: #28c2c1;
                }
                &:focus {
                    color: #c2c2c2;
                }
            }
        }
    }
    button {
        color: #c2c2c2;
    }

    .color-alert {
        color: #28c2c1;
    }

    .navbar-brand {
        margin-top: -8px;
    }

    .fixed-brand {
        width: auto;
        @media (min-width: $screen-sm) {
            width: 250px;
        }
    }

    #brand-logo {
        height: 45px;
        margin-top: 10px;
        margin-left: 45px;
        @media (max-width: $screen-sm) {
            margin-top: 0px;
            margin-left: 10px;
            height:40px;
        }
        @media (max-width: $screen-xs) {
            margin-top: 0px;
            margin-left: 10px;
            height:35px;
        }
    }
}