@import "_cookie-consent.scss";

.pc-footer {
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: #f7f7f9 !important;

  @media screen and (min-width: 992px){
    padding-top: 55px;
    height: 230px;
    margin-top:-130px;

    .copyright {
      padding-right: 12px;
    }

    #mobile-icons{
      display: none;
    }
  }

  @media screen and (max-width: 991px)
                and (min-width: 768px){
    height: 710px;
  }

  @media screen and (max-width: 768px){
    height: 730px;
  }

  @media screen and (max-width: 991px){
    margin-top: -280px;

    .button_row{
      margin-top: 40px;
    }

    .copyright{
      margin-top: 40px;
      text-align:center !important;

      p{
        white-space: normal;
        word-break: break-word;
      }
    }

    #mobile-icons{
      display: block;
      margin-top: 21px;
      text-align: center;
    }

    #desk-icons{
      display: none;
    }
  }

  p {
    color: #211b1b;
    opacity: 0.5;
    font-size: 10px;
    white-space: nowrap;
    font-weight: 700;
    letter-spacing: 1.6px;
    text-transform: uppercase;
  }
  a {
    color: #666;
    text-decoration: none;
  }
  .row {
    @media screen and (min-width: 991px){
      padding:25px 0 0 0;
    }
    @media screen and (max-width: 769px){
      margin-left: 0;
      margin-right: 0;
    }
  }

  select {
    //Add later this height, when refactor footer
    //height: 40px;
    width: 67px;
    color: #454553;
    padding-left: 14px;
    background: url('/themes/photo-club/images/footer-icons/arrow-down.svg') no-repeat right #fff;
    background-size: 10px;
    -webkit-appearance: none;
    background-position-x: 40px;
  }
  .button_row{
    text-align: center;

    @media screen and (min-width: 991px){
      .links{
        float:left;
        margin-left: 24px;
      }

      #lang_pick{
        float:right;
        margin-right: 14px;
        margin-left: -10px;
      }
    }

    & a{
      color: #dddde2;
      text-transform: uppercase;
    }

    & select{
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      height: 40px;
      margin-top: -50px;
      margin-left: 20px;
      border-radius: 6px;
      letter-spacing: 1.32px;
      font-weight: 700;
      font-size: 11px;

      @media screen and (max-width: 992px){
        margin-left: 0 !important;
      }
      @media screen and (max-width: 991px){
        margin-top: 12px;
      }
    }
  }
  #selector-p{
    opacity: 1 !important;
  }

  hr{
    background-color: #c2c2c9;
    height: 1px;
    width: 100%;
    margin-top: 0;
    padding: 0;
    margin-bottom: 26px;

    @media screen and (max-width: 991px){
      margin-bottom: 21px;
    }
  }
  .footer_icons_row{
    padding-top: 0;

    & img{
      @media screen and (max-width: 769px){
        margin-top: 10px;
      }
    }

    .icons-row{
      @media screen and (min-width: 991px){
        padding-left: 35px;
      }
      text-align: left;

      img{
        display: inline;

        @media screen and (min-width: 991px){
          padding-right: 17px;
        }
      }
    }
  }

  #footer-credit-cards{
    @media screen and (max-width: 991px){
      margin-top: 20px;
    }
  }

  p{
    @media screen and (max-width: 769px) {
      margin-top: 10px;
      text-align: center;
    }
  }
  .language-selector{
    @media screen and (max-width: 991px){
      float: none;
      text-align: center !important;
      margin-bottom: 38px;
    }
  }

  .footer-hr{
    border-top: 1px solid #bebec0;
    border-radius: 10px;
    background-color: #dddde2;
    border-bottom: 1px solid #bebec0;
  }

  .gallery-footer-logos {
    margin-top: 40px;
    height: 70px;
  }

  .footer-icon {
    padding-top: 50px;
  }

  .picanova-logo {
    height: 33px;
    width: 93px;
  }

  .col-footer{
    @media screen and (min-width: 991px){
      margin-bottom: 8px;
      margin-right: 20px;
      display: inline-block;
    }

    @media screen and (min-width: 1200px){
      margin-right: 35px;
    }

    @media screen and (max-width: 991px){
      margin-bottom: 30px;
    }

  }

  .footer_icons_row{
    .col-xs-4{
      @media screen and (max-width: 400px){
        padding-left: 0;

        img{
          margin-left: -5px;
        }
      }
    }

    .icons-row{
      @media screen and (max-width: 769px){
        padding-left: 15px;
        margin-top: -35px;
      }
    }
  }
}