.dz-default {
    min-height: 100px;
}

#dz-previews {
    margin-top: 20px;
    margin-bottom: 20px;
    display: none;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

#dz-previews #template {
    padding: 5px;
}

#dz-previews .preview img {
    width: 100px;
}

#dz-previews .file-row {
    padding: 10px;
}

#dz-previews .progress {

}

#dz-previews .file-action-buttons {
    padding-top: 19px;
}

#dz-upload-intro {
    padding: 20px;
}

#dz-upload-intro .glyphicon {
    font-size: 10em;
    color: #8e8e8e;
}

#dz-upload-intro .intro-text {
    margin-top: 20px;
    font-size: 1.5em;
    color: #8e8e8e;
}
#dz-previews {
    .file-action-buttons {
        .cancel {
            i {
                padding: 10px 5px;
                @media (max-width: $screen-sm) {
                    padding: 10px 0px;
                }

            }
        }
    }
}
#dz-actions {
    .btn {
        @media (max-width: $screen-sm) {
            width: 100%;
            margin-bottom:10px;
            margin-right: 0px;
            margin-left: 0px;
        }
    }

}