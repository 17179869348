.photokina-award-images {
  $likedColor: #1abc9c;
  $dislikedColor: #e74c3c;

  @media (max-width: 1500px) and (min-width: 1200px) {
    &.col-lg-3 {
      width: 33%;
    }
  }
  .thumbnail {
    height: 460px;
    img {
      height: 300px;
    }
  }
  .thumb-icons {
    font-size: 22px;
    position: absolute;
    left: 30px;
    bottom: 25px;
    .load-likes {
      color: $likedColor
    }
    .load-dislikes {
      color: $dislikedColor
    }
    .glyphicon {
      color: grey;
      display: inline;
      font-size: 1.125em;
      cursor: pointer;
    }
    .glyphicon-thumbs-up {
      color: grey;
      &.liked {
        color: $likedColor;
      }
    }
    .glyphicon-thumbs-down {
      color: grey;
      &.disliked {
        color: $dislikedColor;
      }
    }
  }
}