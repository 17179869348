#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  background-color: #fafafa;
  padding-bottom: 158px;
  position: relative;

  @media (min-width: $screen-sm) {
    padding-left: 300px;
  }

  &.toggled {
    padding-left: 300px;
    overflow: hidden;

    @media (min-width: $screen-sm) {
      padding-left: 0;
    }

    #sidebar-wrapper {
      width: 300px;
      @media (min-width: $screen-sm) {
        width: 300px;
      }
    }

    #page-content-wrapper {
      position: relative;
      margin-right: 0px;
      @media (min-width: $screen-sm) {
        position: relative;
        margin-right: 0;
        padding-left: 300px;
      }
    }
  }

  #sidebar-wrapper {
    z-index: 1000;
    position: absolute;
    left: 300px;
    width: 0;
    height: 100%;
    margin-left: -300px;
    overflow-y: none;
    background: #f2f2f2;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    @media (min-width: $screen-sm) {
      width: 300px;
    }
    #menu {
      position: relative;
      margin-bottom: 50px;
    }
  }

  #page-content-wrapper {
    position: absolute;
    padding: 15px;
    width: 100%;
    background-color: #fafafa;
    min-height:800px;

    @media (max-width: $screen-sm) {
      padding: 0px;
    }
    @media (min-width: $screen-sm) {
      padding: 20px;
      position: relative;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }
  }
}