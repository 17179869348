/*
 * Slider for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * SCSS File by Detlef Beyer
 */

@import "variables";
@import "mixins";
@import "rules";