body#error-body {
  background: #fbfbfb;
  font-family: 'Source Sans Pro', sans-serif;

  .well {
    margin: 10% auto;
    text-align: center;
    padding: 25px;
    max-width: 600px;
  }

  h1, h2, h3, p {
    margin: 0;
  }

  p {
    font-size: 17px;
    margin-top: 25px;
    a {
      .btn {
        margin: 0 5px;
      }
    }
  }
}