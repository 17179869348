#products {
  .list-group-item {
    padding: 0;

    label {
      padding: 10px 15px;
      display: block;
      font-weight: normal;
      margin-bottom: 0;
      cursor: pointer;
    }
  }

  ul {
    padding: 0;
  }

  .successColor {
    background-color: #fcf8e3;
  }
}

#preview-wrapper {
  .canvas-container {
    margin: 20px auto;
  }

  .range{
    padding-top: 6px;
  }

  .zoom-input{
    padding-bottom: 20px;
  }
}

#custom-ratio-list {
  ul {
    padding-left: 0;
    margin-bottom: 0;
    li {
      list-style-type: none;
      .custom-radio {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                         supported by Chrome and Opera */

        input[type="radio"] {
          position: absolute;
          opacity: 0;
          + label {
            &:before {
              content: '';
              border-radius: 100%;
              border: 1px solid #999;
              display: inline-block;
              width: 20px;
              height: 20px;
              position: relative;
              margin-right: 10px;
              vertical-align: bottom;
              cursor: pointer;
              text-align: center;
              transition: all 250ms ease;
            }
          }
          &:checked {
            + label {
              &:before {
                background-color: #E43427;
                box-shadow: inset 0 0 0 5px #fafafa;
              }
            }
          }
          &:focus {
            + label {
              &:before {
                outline: none;
              }
            }
          }
          + label {
            margin-bottom: 0;
            font-weight: normal;
            &:empty {
              &:before {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 608px) {
  .lower-canvas{
    position: relative !important;
  }
  .canvas-container {
    width: 100% !important;
    height: auto !important;
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .preview-buttons {
    padding-top: 20px;
    .button-width {
      min-width: 48%;
    }
  }

}