@media screen and (max-width: 1385px) {
  .responsive-table {
    display: block;
    border: 1px solid #DDDDDD;
    border-radius: 4px 0 0 0;
    background-color: #F5F5F5;

    > tbody > tr:nth-of-type(odd) {
      background-color: #F5F5F5;
    }

    .mobile-inline-content {
      display: inline-block !important;
      vertical-align: middle;

      &.full-width {
        width: 100%;
      }
      .btn-default {
        width: 142px;
      }

      @media (min-width: 768px) {
        &.hidden-sm {
          display: none !important;
        }
      }

    }

    thead, tbody, tfoot, th, td, tr {
      display: block;

    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tbody {
      tr {
        border: none;
        padding-top: 16px;
        border-bottom: 0;
        position: relative;

        td {
          border: none;
          position: relative;
          padding: 0 16px 16px;

          &.phone-hidden {
            display: none;
            &.display {
              display: block;
            }
          }

          &:last-child {
            text-align: left;
          }

          &:before {
            min-width: 70px;
            display: inline-block;
            vertical-align: middle;
            white-space: nowrap;
            content: attr(data-title);
            color: #999999;
            font-size: 10px;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 16px;
            padding-right: 10px;
          }

          .btn {
            width: 100%;
            max-width: 250px;

            &:hover {
              span {
                display: none;
              }
            }
          }

          &.social-column {

            div {
              display: inline-block;
            }
          }

          &.more-details {
            height: 32px;
            a {
              position: absolute;
              left: 0;
              right: 0;
              margin: auto;
              text-align: center;
              color: #E43427;
              font-size: 12px;
              font-weight: 600;
              letter-spacing: 1px;
              line-height: 16px;
              text-decoration: none;

              &:before,
              &:after {
                content: '';
                height: 1px;
                background-color: #999;
                position: absolute;
                left: 16px;
                right: 16px;
                margin: auto;
                top: 0;
              }

              &:after {
                bottom: 0;
                top: auto;
                z-index: 10;
              }
            }
          }

        }
      }
    }

  }
}
@media screen and (min-width: 1385px) {
  .responsive-table {
    tbody {
      tr {
        td {

          &.more-details {
            display: none;
          }

        }
      }
    }

  }
}