@import "../bootstrap-main/bootstrap";
@import "../bootstrap-main/bootstrap/mixins";
@import "dropzone";
@import "sidebar";
@import "navbar";
@import "wrapper";
@import "preview_configurator";
@import "settings_ratio";
@import "../partials/modal";
@import "../bootstrap-pg-slider/bootstrap-slider";
@import "../bootstrap-select/bootstrap-select";
@import "../partials/responsive-table";
@import "award2018";
@import "../../themes/photo-club/sass/partials/footer";
@import "corporate";

html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: 'Lato';
  color: #404040;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}

a {
  color: #666;
  text-decoration: none;
  outline: none !important;
  &:focus,
  &:hover {
    color: $brand-primary;
    text-decoration: none;
  }
}

h1 {
  margin-top: 0px;
  font-size: 30px;
  font-weight: bold;
}

#share-app {
  .radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
    margin-left: 0;
  }
}

.form-group {
  &.required {
    .control-label:after {
      content: " *";
      color: $brand-primary;
      font-weight: bold;
    }
  }
  .control-label {
    font-weight: normal;
  }
}

.cursor {
  cursor: pointer;
}

.tag-search-badge {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.footer {
  z-index: 1001;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #000;
  color: #cfcfcf;
  border-top: solid 1px #e7e7e7;

  @media (max-width: $screen-md) {
    display: none;
  }

  a {
    color: inherit;
  }
}

.col-shop-link {
  max-width: 300px;
  min-width: 300px !important;
}

.header-row {
  min-height: 56px;
  .header-form {
    > *,
    form > * {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .right-top-input-container {
      min-width: 140px;
      max-width: 210px;
      text-align: left;
      .right-top-input {
        min-width: 130px;
        max-width: 200px;
        display: inline-block;
      }
      label {
        font-weight: normal;
        margin: 0px;
        text-align: left;
        display: block;
      }
      @media (max-width: $screen-sm) {
        width: 100%;
        max-width: 100%;

        .right-top-input {
          max-width: 100%;
          display: inline-block;
        }
      }
    }
  }
}

.table {
  i {
    padding: 10px 5px;
    &.fa-xs {
      padding: 0px 0px;
    }
  }
  th a {
    color: inherit;
  }
  a {
    text-decoration: underline;
  }
  .table-action-link {
    text-decoration: none;
    i.fa {
      padding: 0px;
    }
  }
  &.modal-details-table {
    margin-top: 5px;
    td {
      width: 100%;
    }
    th {
      white-space: nowrap;
    }
    tr:first-child {
      td, th {
        border-top: none;
      }
    }
  }
}

.sortable {
  &:hover {
    white-space: nowrap;
  }
  i {
    padding: 0px;
    width: 8px;
    margin-left: 5px;
    .fa-sort-asc {
      height: 8px;
      position: relative;
      top: 2px;
    }
    .fa-sort-desc {
      height: 8px;
      position: relative;
      top: -2px;
    }
  }
}

.images-table-thumb-container {
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;

  .images-table-thumb {
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
    max-width: 200px;
  }

  &:hover {
    .images-table-thumb {
      max-width: 100px;
    }
  }
}

.link-input,
.link-input:active,
.link-input:focus {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none !important;
}

.text-red {
  color: $brand-primary;
}

.fa-sort {
  color: #d4d4d4;
}

a.copy-to-clipboard {
  text-decoration: none !important;
}

a.share-link {
  text-decoration: none !important;
  &.shared {
    color: #0eb50f;
  }
  &.share-link-facebook:hover {
    color: #28527a;
  }
  &.share-link-twitter:hover {
    color: #4099ff;
  }
  &.share-link-pinterest:hover {
    color: #bc001c;
  }
  &.share-link-google:hover {
    color: #dc4e41;
  }
}

.select2-container {
  .select2-search__field {
    width: 100% !important;
  }
}

.terms-table {
  width: 100%;
  td, th {
    vertical-align: top;
    padding: 3px;
  }
  th {
    font-size: 1.2em;
  }
  td:first-child {
    width: 50px;
    text-align: left;
  }
  td:nth-child(2) {
    text-align: justify;
  }
}

ul.error-list {
  li {
    list-style: none;
  }
}

.tour-step-background-inverse {
  background: transparent !important;
  border: solid 2px #fff !important;
}

.popover-title {
  background-color: $panel-info-heading-bg;
  &.orange {
    background-color: $panel-warning-heading-bg;
  }
}

.under-tab-filter-form {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid $table-border-color;
  padding: 10px;
  border-radius: $border-radius-base;

  #reportrange {
    background-color: $input-bg;
    cursor: pointer;
    padding: $padding-base-vertical $padding-base-horizontal;
    font-size: $font-size-base;
    line-height: $line-height-base;
    border: 1px solid $input-border;
    border-radius: $input-border-radius;
    width: 100%;
    @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075));

  }
}

.stats-upload-description {
  color: #404040;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;
}

form.form-delete {
  display: inline-block;
}

.line-chart {
  @media (max-width: $screen-sm) {
    margin-left: -15px;
  }
}

.nav-tabs {
  @media (max-width: $screen-sm) {
    border-bottom: none;

    > li {
      float: none;
      + li {
        margin-top: 2px;
        margin-left: 0; // no need for this gap between nav items
      }
      &.active {
        a {
          border-bottom-color: $nav-tabs-active-link-hover-border-color;
          border-radius: $border-radius-base;
        }
      }
    }
  }

}

tr.no-wrap {
  th, td {
    white-space: nowrap;
  }
}

tr.deal-of-week {
  td {
    border-bottom: 2px solid $gray-light;
  }
}

#com-slider {
  width: 100%;
  margin-left: 10px;
}

#com-slider .slider-track-high {
  background: #bcbcbc;
}

#com-slider .slider-selection {
  background: #FF8282;
}

#com-slider .slider-handle {
  background: #e43427;
}

#com-slider .slider-tick {
  background: #bcbcbc;
}

#com-slider .slider-tick.in-selection {
  background: #e43427;
}

[v-cloak] {
  display: none;
}

.ratio-error {
  color: #e43427;
  font-weight: 600;

  &:hover {
    &:not(.active) {
      color: #e43427;
    }
  }
}

#dpi-alert-box {
  opacity: 0.6;
  position: absolute;
  text-align: center;
  width: 260px;
  height: 50px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px;
  word-break: keep-all

}

.canvas-container {
  border: 1px solid #999;
}

.price-input {
  min-width: 100px;
}

@media (max-width: 768px) {
  .btn-stats-upload {
    margin-bottom: 10px
  }
  .stats-upload-description {
    padding: 10px 0
  }
  .breadcrumb {
    display: none;
  }
  .header-row {
    .col-md-4, .col-xs-4 {
      margin: 10px;
    }
  }
}

@media screen and (max-width: 992px) {
  // Fields in responsive table for mobile
  .images-table-thumb-container {
    display: inline-block;
    vertical-align: middle;

    .images-table-thumb {
      max-width: 100px;
    }
  }
  .link-input {
    font-size: 12px;
  }
  .input-inline-edit {
    input {
      width: 130px;
    }
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 130px;
      display: inline-block;
    }
    a {
      padding: 5px;
    }
  }
  .generating-previews-block {
    max-width: 152px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.product-overview {
  input[type=number] {
    width: 50px;
  }
  .product-header {
    position: relative;
  }
  .product-priority {
    position: absolute;
    right: 12px;
    top: 12px;
    input {
      color: #404040;
    }
  }
}

.disabled {
  pointer-events: none !important;
  &.grey {
    color: grey !important;
  }
}

.phone-case-info {
  padding: 15px;
  border: 1px solid #ddd;
  background: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.cd-page-container {
  padding-top: 35px;

  h1 {
    margin-bottom: 33px;
  }
}

@media (min-width: 1200px) {
  .phone-case-info {
    position: relative;
    padding: 15px;
    border: 1px solid #ddd;
    color: #333;
    background: #fff;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    &.left {
      margin-left: 30px;

      &:before {
        top: 15px;
        bottom: auto;
        left: -23px;
        border-width: 10px 23px 10px 0;
        border-color: transparent #ddd;
      }

      &:after {
        top: 16px;
        bottom: auto;
        left: -21px;
        border-width: 9px 21px 9px 0;
        border-color: transparent #fff;
      }
    }

    &:before {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 40px;
      border-width: 20px 20px 0;
      border-style: solid;
      border-color: #ddd transparent;
      display: block;
      width: 0;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: -13px;
      left: 47px;
      border-width: 13px 13px 0;
      border-style: solid;
      border-color: #fff transparent;
      display: block;
      width: 0;
    }
  }
}

.portfolio-headline {
  color: #333333;
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;
  margin-top: 35px;
  margin-bottom: 33px;
}

.portfolio-description {
  color: #333333;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  margin-bottom: 30px;
}

.portfolio-image {
  img {
    width: 100%;
    -webkit-transition: transform .2s;
    -moz-transition: transform .2s;
    -ms-transition: transform .2s;
    -o-transition: transform .2s;
    transition: transform .2s;
  }
}

.portfolio-block {
  max-width: 400px;
  font-family: Lato, serif;
  margin-bottom: 60px;

  .portfolio-block-title {
    background-color: #E9E9E9;
    border: 1px solid #c5c5c5;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: relative;
    padding: 6px 0 7px 15px;
    cursor: pointer;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
    margin-bottom: 12px;
  }

  &.checked {
    .portfolio-image {
      &:hover {
        img {
          -webkit-transform: scale(1.5);
          -moz-transform: scale(1.5);
          -ms-transform: scale(1.5);
          -o-transform: scale(1.5);
          transform: scale(1.5);
        }
      }
    }
  }

  ul {
    padding-left: 5px;
    color: #333;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 30px;
    padding-top: 0;

    li {
      padding-bottom: 10px;
      list-style-type: none;
      &:before {
        display: inline-block;
        background-color: #333333;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        content: '';
        margin-right: 5px;
      }
    }
  }

  &:not(.checked) {
    table, ul, img {
      opacity: 0.5;
    }
  }

}

.table.portfolio-table {
  margin-bottom: 0;
  td, th {
    padding: 6px;
  }

  .show-more {
    display: none;
  }
  .show-less {
    display: block;
  }

  &.hide-formats {
    .portfolio-hidden-format {
      display: none;
    }
    .show-less {
      display: none;
    }
    .show-more {
      display: block;
    }
  }

}


//Move this to corporate.scss file, right now this file is only on testing and not tested, creating this to avoid conflicts with file creating
.corporate-album-show {
  a {
    text-decoration: none;
  }
}

.fake-password-change {
  color: #a94442;
  display: inline-block;
  padding-top: 30px;
}

@import "auth_body";
@import "error_body";
@import "theme_form";